.date-selector {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 54px);

  .dates {
    width: 90%;
    height: 70%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 23%;
      border-radius: 6px;
      background-color: var(--modal-date-background);
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      margin: 10px 1%;
      padding: 8px 0px;

      &:active {
        opacity: 0.6;
      }

      p {
        margin: 0;
        text-align: center;
      }

      .day {
        font-size: 20px;
      }

      .month-year {
        font-size: 12px;
      }
    }
  }

  .daily {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 12px;
    background-color: var(--primary);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    padding: 14px 0px;
    margin: 10px 0px;

    &:active {
      opacity: 0.6;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: var(--black);
    }
  }
}
