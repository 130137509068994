/*  Input Base  */

.main-input {
  display: block;
  background: $gray;
  border-width: 0;
  padding: 10px;
  color: $black;
  font-family: $font-stack;
  font-size: 22px;
  width: 280px;
}
