.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.gomezle-modal-control {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 12px;
    color: var(--modal-text);
  }

  .modal-header {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .modal-heading {
      margin: 0;
      color: var(--primary);
    }

    div {
      width: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 50%;
        transition: all ease-in 200ms;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        padding: 0;

        .icon {
          color: var(--primary);
          font-size: 30px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  .modal-text {
    font-size: 12px;
    margin: 0px 0;
    color: var(--modal-text);
    margin-bottom: 12px;
  }

  .play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 2px 0px 8px 0px;

    button {
      position: absolute;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: none;
      background-color: var(--player-background);
      border-radius: 50%;
      transition: all ease-in 200ms;
      -webkit-tap-highlight-color: transparent;

      .icon {
        color: var(--player-icon);
        font-size: 16px;
      }
    }
  }

  .guesses {
    width: calc(85% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .guess {
      width: 100%;
      height: 22px;
      padding: 2px 12px;
      border-radius: 6px;
      margin-bottom: 6px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--guess-wrong);

      p {
        font-size: 12px;
        margin: 0;
        margin-top: 2px;
        color: var(--guess-wrong-text);
      }
    }

    .empty {
      background-color: var(--guess-blank);
    }

    .cbmode {
      background-color: #ea580c;
      color: $white;
    }

    .correct {
      background-color: var(--guess-correct);
      p {
        color: var(--guess-correct-text);
      }
    }

    .correct.cbmode {
      background-color: #1e40af;
      color: $white;
    }
  } // guesses

  .totals {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .number {
      width: 17%;

      p {
        margin: 0;
        font-size: 14px;
        color: var(--modal-text);
      }

      .value {
        font-size: 18px;
      }
    }
  }

  .guess-distribution {
    width: 90%;
    color: var(--modal-text);

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 10px 0px;

      .key {
        width: 20px;
      }

      .bar {
        height: 18px;
        background-color: var(--primary);
        text-align: right;
        vertical-align: middle;
        padding: 2px 8px;
        border-radius: 20px;
        margin-left: 10px;
        font-size: 14px;
        color: var(--black);
      }
    }
  }

  .divider {
    width: 65%;
    height: 2px;
    background-color: var(--medium-gray);
    border-radius: 20px;
    margin: 16px 0px 16px 0px;
  }

  .next-share {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .countdown {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
        color: var(--modal-text);
      }

      .name {
        font-size: 16px;
      }

      .value {
        font-size: 22px;
        margin-top: 4px;
      }
    }

    .share {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: none;
        cursor: pointer;
        width: 85%;
        border-radius: 6px;
        transition: all 200ms;
        -webkit-tap-highlight-color: transparent;
        background-color: var(--primary);
        padding: 16px 6px;
        font-family: $swiftle-font;

        p {
          font-size: 16px;
          margin: 0;
          color: var(--white);
        }

        .icon {
          color: var(--white);
          font-size: 20px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  .settings {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px 0;

      h4 {
        margin: 0;
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: normal;
        color: var(--modal-text);
      }

      p {
        font-size: 10px;
        margin: 0;
        color: var(--modal-subtext);
      }
    }

    .row {
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;

      p {
        font-size: 15px;
        margin: 0;
        color: var(--modal-text);
      }

      a {
        font-size: 14px;
        background-color: $medium-gray;
        padding: 5px 12px;
        border-radius: 4px;
        color: var(--black);
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          font-size: 15px;
          margin: 0;
          margin-bottom: 2px;
        }

        .description {
          font-size: 10px;
          text-align: left;
          margin: 0;
          color: $dark-gray;
        }
      }
    }

    .social {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      .buttons {
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        button {
          width: 40px;
          height: 40px;
          background-color: var(--modal-social-background);
          border-radius: 4px;
          cursor: pointer;
          border: none;
          border-radius: 50%;
          -webkit-tap-highlight-color: transparent;

          .icon {
            font-size: 16px;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }

      .techyonic {
        margin: 10px;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        color: var(--modal-text);
      }

      p {
        font-size: 11px;
        margin: 0;
      }
    }
  }

  .modal-answer {
    padding: 10px 0;
    p {
      margin: 0;
      font-size: 14px;
      color: var(--modal-text);
    }

    .name {
      margin: 10px 0px 4px 0px;
      font-size: 18px;
    }
  }

  .modes {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      background-color: var(--guess-blank);
      border: none;
      color: var(--primary);
      border-radius: 100px;

      .icon {
        font-size: 24px;
      }

      p {
        color: var(--primary);
        font-size: 12px;
        margin: 0;
        margin-top: 10px;
        font-family: $swiftle-font;
        font-weight: bold;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }

  .daily-mode {
    width: 70%;
    height: 66px;
    background-color: var(--guess-blank);
    border: none;
    color: var(--primary);
    border-radius: 100px;
    padding: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    margin-top: 20px;

    &:active {
      opacity: 0.6;
    }

    p {
      color: var(--primary);
      font-size: 16px;
      font-family: $swiftle-font;
      font-weight: bold;
    }
  }
}
