/* Swiftle */
.swiftle-control {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: $swiftle-font;
  background-color: var(--background);

  p {
    color: var(--text);
  }

  @include sm {
    height: 100%;
  }

  *:focus {
    outline: none;
  }

  .swiftle-header {
    width: 100%;
    height: 54px;
    border-bottom: var(--header-border) solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;

    .header-content {
      max-width: 600px;
      width: 92%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header-title {
        width: 180px;
        display: flex;
        flex-direction: column;

        a {
          display: flex;
          align-items: center;

          svg {
            margin-left: 4px;
            color: var(--primary);
          }
        }

        h1 {
          color: var(--primary);
          margin: 0;
          font-size: 24px;
        }

        h2 {
          color: var(--primary);
          margin: 0;
          font-size: 14px;
        }
      }

      div {
        width: 94px;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border: none;
          border-radius: 50%;
          transition: all ease-in 200ms;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          padding: 0;
          margin: 0 2px;

          .icon {
            color: var(--primary);
            font-size: 23px;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }

      .header-left {
        justify-content: flex-start;
      }

      .header-right {
        justify-content: flex-end;
      }
    }
  }

  .dev-message {
    z-index: 0;
    text-align: center;
    color: white;
    background-color: var(--guess-blank);
    font-size: 14px;
    padding: 4px 10px;
    border-radius: 30px;
    margin: 0px 8px;
    margin-top: 8px;
  }

  .swiftle-playfield {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: calc(100% - 54px);
    z-index: 0;

    .play {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 76px;
      margin: 12px 0px;

      button {
        position: absolute;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border: none;
        background-color: var(--player-background);
        border-radius: 50%;
        transition: all ease-in 200ms;
        -webkit-tap-highlight-color: transparent;

        .icon {
          color: var(--player-icon);
          font-size: 22px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }

    .date-changer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      p {
        cursor: pointer;
        margin: 0;
        font-size: 14px;
        background-color: var(--primary);
        padding: 6px 10px;
        border-radius: 6px;
        color: var(--background);
      }

      button {
        cursor: pointer;
        width: 34px;
        height: 34px;
        border: none;
        border-radius: 50%;
        transition: all ease-in 200ms;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        padding: 0;
        margin: 0 4px;

        .icon {
          color: var(--primary);
          font-size: 32px;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }

    .skip {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      background-color: var(--guess-input);
      border-radius: 6px;
      width: 17%;
      transition: ease-in 200ms;
      margin: 12px 0px 16px 0px;

      p {
        padding: 7px 24px;
        font-family: $swiftle-font;
        color: var(--guess-input-placeholder);
        font-size: 13px;
        font-weight: bold;
        margin: 0;
      }

      .icon {
        color: white;
        font-size: 16px;
      }

      &:active {
        opacity: 0.7;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .guesses {
      width: calc(85% - 32px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .guess {
        width: 100%;
        height: 34px;
        padding: 2px 16px;
        border-radius: 6px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--guess-wrong);

        p {
          font-size: 14px;
          margin: 0;
          margin-top: 2px;
          color: var(--guess-wrong-text);
        }
      }

      .empty {
        background-color: var(--guess-blank);
      }

      .cbmode {
        background-color: #ea580c;
        color: $white;
      }

      .correct {
        background-color: var(--guess-correct);
        p {
          color: var(--guess-correct-text);
        }
      }

      .correct.cbmode {
        background-color: #1e40af;
        color: $white;
      }

      .album {
        background-color: var(--guess-album);
      }

      .skipped {
        background-color: var(--guess-input);

        p {
          font-style: italic;
        }
      }
    } // guesses

    .search {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 85%;
      margin-bottom: 8px;
      background-color: $white;
      border-radius: 6px;
      background-color: var(--guess-input);

      input {
        @extend .main-input;
        width: calc(100% - 12px);
        height: 34px;
        padding: 2px 0px 2px 16px;
        font-size: 14px;
        background-color: transparent;
        font-family: $swiftle-font;
        color: var(--text);

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: var(--guess-input-placeholder);
        }
      }

      button {
        cursor: pointer;
        width: 27%;
        border: none;
        background-color: var(--player-icon);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: ease-in 200ms;

        p {
          font-family: $swiftle-font;
          color: var(--background);
          font-size: 13px;
          font-weight: bold;
          margin: 0;
        }

        .icon {
          color: white;
          font-size: 16px;
        }

        &:active {
          opacity: 0.7;
          -webkit-tap-highlight-color: transparent;
        }
      }

      .has-songs {
        border-bottom-right-radius: 0;
      }

      .has-songs-alt {
        border-top-right-radius: 0;
      }
    } //search

    .search-has-songs {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .search-has-songs-alt {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .songs {
      max-width: 510px;
      width: 85%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--guess-input);
      border-radius: 6px;
      opacity: 1;

      .song {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        text-align: center;
        font-family: $swiftle-font;
        height: 34px;
        padding: 2px 16px;
        border-radius: 6px;
        margin-bottom: 8px;

        &:active {
          opacity: 0.6;
          -webkit-tap-highlight-color: transparent;
        }

        p {
          font-size: 14px;
          margin: 0;
          margin-top: 2px;
          color: var(--guess-wrong-text);
        }
      }
    }

    .songs-has-songs {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .songs-has-songs-alt {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .answer {
      width: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--guess-correct);
      padding: 10px 8px;
      transition: all 200ms;
      border-radius: 10px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      h5 {
        margin: 10px 0;
        color: var(--answer-text);
      }

      p {
        margin: 0;
        font-size: 12px;
        text-align: center;
        color: var(--answer-text);
      }

      .name {
        font-size: 14px;
      }

      &:active {
        opacity: 0.6;
      }
    }

    .answer.cbmode {
      background-color: #1e40af;
      color: $white;
    }
  }

  .toast {
    background-color: var(--medium-gray);
    border-radius: 6px;
    position: absolute;
    bottom: 12%;
    font-family: $swiftle-font;
    padding: 10px 12px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.3s ease-out;
    z-index: 999;

    p {
      margin: 0;
      color: var(--black);
    }
  }

  .toast.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .footer {
    position: absolute;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;

    a {
      font-size: 15px;
      text-decoration: underline;
      color: var(--footer-text);
      margin-bottom: 4px;
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  .other-game {
    width: 100%;
    aspect-ratio: 5.75;
    background-color: var(--guess-blank);
    border: none;
    border-radius: 100px;
    padding: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 6px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    align-self: center;

    &:active {
      opacity: 0.6;
    }

    p {
      color: #e7c684;
      font-size: 18px;
      font-family: $font-elmwood;
      font-weight: bold;
      margin: 0;
    }
  }
} // swiftle-control
