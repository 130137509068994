/* Home Page */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;

  .top {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    z-index: 1;

    img {
      height: 35%;
      object-fit: contain;
    }

    h1 {
      max-width: 85%;
      text-align: center;
      margin: 0;
      padding: 0;
      color: #383838;
      font-size: 18px;
    }

    #watch {
      border: 1px solid #383838;
      border-radius: 30px;
      padding: 8px 20px;
      background: none;
      font-size: 19px;
      color: #383838;
      cursor: pointer;
      font-family: $font-elmwood;
      padding-top: 12px;
      transition: all 150ms ease-in;

      &:hover {
        background-color: #383838;
        color: #e7c684;
      }
    }

    #preorder {
      font-family: $font-elmwood;
      color: #e7c684;
      font-size: 28px;
      margin: 0;
    }

    #stores {
      display: flex;
      width: 95%;
      max-width: 500px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      button {
        width: 48%;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        opacity: 0.9;
        transition: all 150ms ease-in;

        img {
          width: 100%;
        }

        &:hover {
          opacity: 0.6;
        }
      }

      .solo {
        width: 65%;
      }
    }

    #shop-merch {
      position: absolute;
      left: 20px;
      top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #ffffff11;
      padding: 20px 26px;
      border-radius: 10px;
      outline: none;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #ffffff22;
      }

      img {
        width: 30px;
        margin-right: 10px;
      }

      p {
        font-size: 22px;
        color: white;
        margin: 0;
        font-family: $font-elmwood;
        font-weight: bold;
        padding-top: 4px;
        background-image: linear-gradient(to right, #d53369, #cbad6d);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    width: calc(100% - 32px);
    height: calc(10% - 1px);
    border-top: 1px solid #121212;
    z-index: 1;

    .section {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      button {
        height: 36%;
        aspect-ratio: 1;
        border: none;
        background: none;
        opacity: 0.4;
        cursor: pointer;
        transition: all 150ms ease-in;

        &:hover {
          opacity: 1;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      input {
        height: 35%;
        border: 1px solid #282828;
        border-radius: 30px;
        background: none;
      }
    }

    .right {
      justify-content: flex-end;
      gap: 20px;

      .card {
        height: 50%;
        aspect-ratio: 1.8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #181818;
        transition: all 150ms ease-in;
      }

      #here {
        color: white;
        font-family: $here-font;
        cursor: pointer;

        &:hover {
          background-color: white;
          color: black;
        }
      }

      #swiftle {
        color: #ff7070;
        font-family: $swiftle-font;
        font-size: 13px;

        &:hover {
          background-color: #ff7070;
          color: black;
        }
      }
    }
  }
} // home-control
