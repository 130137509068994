/* Typography */

// Import front from Google CDN
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap");

@font-face {
  font-family: "GeosansLight";
  src: local("GeosansLight"), url("./../../assets/fonts/GeosansLight.ttf");
}

@font-face {
  font-family: "TaylorSwiftHandwriting";
  src: local("TaylorSwiftHandwriting"),
    url("./../../assets/fonts/TaylorSwiftHandwriting.ttf");
}

// Default font
$font-stack: "Source Sans Pro", sans-serif;
$here-font: "GeosansLight";
$swiftle-font: "Quicksand";
$font-footer: "Raleway";
$font-elmwood: "Josefin Sans";
$font-taylor: "TaylorSwiftHandwriting";
$font-handwriting: "Merienda";
