html,
body {
  scroll-behavior: smooth;
  font-family: $font-stack;
  margin: 0;
  padding: 0;
  background: $background;
  text-decoration: none;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.base-control {
  height: 100%;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  pointer-events: none;

  canvas {
    pointer-events: none;
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
