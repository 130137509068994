.mode-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .modes {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .mode {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 40%;
      aspect-ratio: 1;
      border: none;
      background-color: #ffffff11;
      border-radius: 50%;
      font-family: $swiftle-font;
      font-weight: bold;
      margin: 0px 5px;
      -webkit-tap-highlight-color: transparent;
      overflow: hidden;

      .icon {
        font-size: 36px;
        color: var(--primary);
      }

      p {
        font-size: 14px;
        color: var(--primary);
        margin: 0;
      }

      #new {
        background-color: var(--primary);
        width: 100%;
        height: 15%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        font-size: 11px;
        margin: 0;
        color: #282729;
        font-weight: bold;
      }
    }
  }

  .daily-mode {
    cursor: pointer;
    width: 80%;
    aspect-ratio: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ffffff11;
    margin-top: 24px;
    border-radius: 50px;
    font-family: $swiftle-font;
    -webkit-tap-highlight-color: transparent;

    p {
      font-size: 24px;
      color: var(--primary);
      margin: 0;
    }
  }
}
