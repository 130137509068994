/* Navigation Bar Component */

.nav-control {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  z-index: 6000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background;

  .nav-logo {
    height: 100%;
    margin-left: 20px;
    cursor: pointer;
  } // nav-logo
} // nav-control
