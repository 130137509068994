/* Main Button */

.main-btn {
  min-width: 120px;
  display: block;
  padding: 8px 12px;
  background: white;
  border-color: $black;
  font-size: 20px;
  font-family: $font-stack;
  font-weight: 600;
  transition: 0.3s all;
  color: $black;
  letter-spacing: 0.5px;
  border-radius: 8px;
  border-width: 3px;
  border-style: solid;

  &:hover {
    cursor: pointer;
    color: white;
    background: $black;
    transition: 0.3s all;
    outline: none;
  }
  &:focus{
    outline: none;
  }
}
