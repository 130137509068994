/* Paragraph Base */
@keyframes slide {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

.p {
  display: block;
  font-size: 26px;
  font-family: $font-stack;
  color: $black;
}
