#score {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 8px;
  font-family: $swiftle-font;

  #current {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    #icon {
      color: var(--primary);
      font-size: 50px;
    }

    p {
      color: var(--primary);
      font-size: 54px;
      margin: 0;
    }
  }

  #best {
    margin: 0;
    color: var(--text);
    margin-bottom: 10px;
  }

  #times {
    min-height: 26px;
    display: flex;
    margin-top: 10px;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    #time {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: var(--guess-input);
      color: var(--text);
      text-align: center;

      p {
        margin: 0;
        font-size: 17px;
      }
    }
  }

  #share {
    border: none;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 10px;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;

    .icon {
      font-size: 20px;
      color: var(--text);
    }

    p {
      font-size: 20px;
      margin: 0;
    }
  }
}
