/* Colour pallettes */

$bronze-hover: #c6aa9ddd;
$red: #e80131;
$red-hover: #e8013199;
$red-new: #ff5858;
$lightred: #ff9999;
$faintred: #ffebeb;
$black: #383838;
$background: black;
$white: #f2f2f2;
$gray: #eaeaea;
$light-gray: #e8e8e8;
$medium-gray: #d2d2d1;
$dark-gray: #8e8e8d;
$green: #14b137;
$green-new: #c2eade;
$blue: #62acd1;

$themes: (
  "swiftle": (
    "light": (
      "colors": (
        "primary": #ff7070,
        "secondary": #ff7070,
        "background": "#ffffff",
        "text": "#2a2a2a",
        "header-border": "#d2d2d1",
        "player-background": "#f2f2f2",
        "player-icon": "#ff7070",
        "guess-input": #f0f0f0,
        "guess-input-placeholder": #8e8e8d,
        "guess-blank": #e9e9e9,
        "guess-album": #fffcd6,
        "guess-wrong": #ffebeb,
        "guess-wrong-text": #2a2a2a,
        "guess-correct": #c2eade,
        "answer-text": #2a2a2a,
        "footer-text": #2a2a2a,
        "modal-text": #2a2a2a,
        "modal-subtext": #8e8e8d,
        "modal-date-background": "#e8e8e8",
        "modal-social-background": "#e8e8e8",
        "black": #2a2a2a,
        "white": #f2f2f2,
        "light-gray": "#e8e8e8",
        "medium-gray": "#d2d2d1",
        "dark-gray": "#8e8e8d",
      ),
    ),
    dark: (
      colors: (
        "primary": #ff7070ee,
        "secondary": #ff7070,
        "background": "#17171a",
        "text": "#f2f2f2",
        "header-border": "#48484a",
        "player-background": "#2c2c2e",
        "player-icon": "#ff7070cc",
        "guess-input": #2c2c2e,
        "guess-input-placeholder": #8e8e8d,
        "guess-blank": #222224,
        "guess-album": "#FFF78566",
        "guess-wrong": #ff707066,
        "guess-wrong-text": #d2d2d1,
        "guess-correct": #42885e66,
        "guess-correct-text": #d2d2d1,
        "answer-text": #d2d2d1,
        "footer-text": #b2b2b1,
        "modal-text": #c2c2c1,
        "modal-subtext": #8e8e8d,
        "modal-date-background": "#48484a",
        "modal-social-background": "#d2d2d1",
        "black": #2a2a2a,
        "white": #f2f2f2,
        "light-gray": "#e8e8e8",
        "medium-gray": "#d2d2d1",
        "dark-gray": "#8e8e8d",
      ),
    ),
    "midnights": (
      "colors": (
        "primary": #6893a9ee,
        "secondary": #6893a9,
        "background": "#18181a",
        "text": "#f2f2f2",
        "header-border": "#48484a",
        "player-background": "#2c2c2e",
        "player-icon": "#6893a9cc",
        "guess-input": #2c2c2e,
        "guess-input-placeholder": #8e8e8d,
        "guess-blank": #222224,
        "guess-album": "#FFF78566",
        "guess-wrong": #ff707039,
        "guess-wrong-text": #d2d2d1,
        "guess-correct": #42885e66,
        "guess-correct-text": #d2d2d1,
        "answer-text": #d2d2d1,
        "footer-text": #b2b2b1,
        "modal-text": #c2c2c1,
        "modal-subtext": #8e8e8d,
        "modal-date-background": "#48484a",
        "modal-social-background": "#d2d2d1",
        "black": #2a2a2a,
        "white": #f2f2f2,
        "light-gray": "#e8e8e8",
        "medium-gray": "#d2d2d1",
        "dark-gray": "#8e8e8d",
      ),
    ),
  ),
  "gomezle": (
    "light": (
      "colors": (
        "primary": #6fc392,
        "secondary": #6fc392,
        "background": "#ffffff",
        "text": "#2a2a2a",
        "header-border": "#d2d2d1",
        "player-background": "#e2e2e2",
        "player-icon": #6fc392,
        "guess-input": #d9d9d9,
        "guess-input-placeholder": #8e8e8d,
        "guess-blank": #e2e2e2,
        "guess-album": #fffcd6,
        "guess-wrong": #ffc8c8,
        "guess-wrong-text": #2a2a2a,
        "guess-correct": #c2eade,
        "answer-text": #2a2a2a,
        "footer-text": #2a2a2a,
        "modal-text": #2a2a2a,
        "modal-subtext": #8e8e8d,
        "modal-date-background": "#e8e8e8",
        "modal-social-background": "#e8e8e8",
        "black": #2a2a2a,
        "white": #f2f2f2,
        "light-gray": "#e8e8e8",
        "medium-gray": "#d2d2d1",
        "dark-gray": "#8e8e8d",
      ),
    ),
    "dark": (
      "colors": (
        "primary": #6fc392ee,
        "secondary": #6fc392,
        "background": "#18181a",
        "text": "#f2f2f2",
        "header-border": "#48484a",
        "player-background": "#2c2c2e",
        "player-icon": "#6fc392cc",
        "guess-input": #2c2c2e,
        "guess-input-placeholder": #8e8e8d,
        "guess-blank": #222224,
        "guess-album": "#FFF78566",
        "guess-wrong": #ff707039,
        "guess-wrong-text": #d2d2d1,
        "guess-correct": #42885e66,
        "guess-correct-text": #d2d2d1,
        "answer-text": #d2d2d1,
        "footer-text": #b2b2b1,
        "modal-text": #c2c2c1,
        "modal-subtext": #8e8e8d,
        "modal-date-background": "#48484a",
        "modal-social-background": "#d2d2d1",
        "black": #2a2a2a,
        "white": #f2f2f2,
        "light-gray": "#e8e8e8",
        "medium-gray": "#d2d2d1",
        "dark-gray": "#8e8e8d",
      ),
    ),
  ),
);

@each $app-name, $app-vars in $themes {
  @each $theme-name, $theme-vars in $app-vars {
    $colors: map-get($theme-vars, "colors");

    .#{$app-name}-control.#{$theme-name} {
      // define a css variable for each color
      @each $name, $color in $colors {
        --#{$name}: #{$color};
      }
    }

    .#{$app-name}-modal-control.#{$theme-name} {
      // define a css variable for each color
      @each $name, $color in $colors {
        --#{$name}: #{$color};
      }
    }
  }
}
