.swiftle-lyrics-playfield {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 54px);
  z-index: 0;

  #lyrics {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 0;

    p {
      font-size: 24px;
      color: var(--text);
      margin: 0;
      font-family: $font-handwriting;
      text-align: center;
      letter-spacing: 0.6px;
    }
  }

  .search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 8px;
    background-color: $white;
    border-radius: 6px;
    background-color: var(--guess-input);
    overflow: hidden;

    #timer {
      height: 6px;
      background-color: var(--primary);
    }

    #timer.panic {
      background-color: $red-new;
    }

    input {
      @extend .main-input;
      width: calc(100% - 12px);
      height: 34px;
      padding: 2px 0px 2px 16px;
      font-size: 14px;
      background-color: transparent;
      font-family: $swiftle-font;
      color: var(--text);

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--guess-input-placeholder);
      }
    }

    button {
      cursor: pointer;
      width: 27%;
      border: none;
      background-color: var(--player-icon);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      transition: ease-in 200ms;

      p {
        font-family: $swiftle-font;
        color: var(--background);
        font-size: 13px;
        font-weight: bold;
        margin: 0;
      }

      .icon {
        color: white;
        font-size: 16px;
      }

      &:active {
        opacity: 0.7;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .has-songs {
      border-bottom-right-radius: 0;
    }

    .has-songs-alt {
      border-top-right-radius: 0;
    }
  } //search

  .search-has-songs {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .search-has-songs-alt {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .songs {
    max-width: 510px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--guess-input);
    border-radius: 6px;
    overflow: hidden;
    opacity: 1;

    .song {
      transition: all 70ms;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 32px);
      cursor: pointer;
      text-align: center;
      font-family: $swiftle-font;
      height: 34px;
      padding: 4px 16px;

      &:active {
        -webkit-tap-highlight-color: transparent;
      }

      p {
        font-size: 14px;
        margin: 0;
        margin-top: 2px;
        color: var(--guess-wrong-text);
      }
    }

    .wrong {
      transition: all 70ms;
      background-color: var(--guess-wrong);
    }

    .correct {
      transition: all 70ms;
      background-color: var(--guess-correct);
    }
  }

  .songs-has-songs {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .songs-has-songs-alt {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  #missed-answer {
    width: 85%;
    padding: 4px 16px;
    color: #42885e;
    border-radius: 6px;
    text-align: center;
    margin-top: 20px;
  }

  .playbutton {
    cursor: pointer;
    border: none;
    background-color: var(--guess-input);
    padding: 8px 20px;
    border-radius: 40px;
    -webkit-tap-highlight-color: transparent;

    p {
      font-size: 30px;
      color: var(--text);
      margin: 0;
      margin-top: 10px;
      font-family: $font-handwriting;
    }
  }

  .playbutton.transparent {
    background-color: transparent;
  }
}
