.swiftle-blank-space-playfield {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 54px);
  z-index: 0;

  #lyrics {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 0;

    p {
      font-size: 18px;
      color: var(--text);
      margin: 0;
      font-family: $font-handwriting;
      text-align: center;
      letter-spacing: 0.6px;
    }
  }

  #inputwrap {
    overflow: hidden;
    width: 2;
    height: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  .bar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 2px;
    width: 75%;
    background-color: $white;
    border-radius: 12px;
    background-color: var(--guess-input);
    overflow: hidden;
    font-family: $swiftle-font;
    font-size: 16px;
    margin-top: 8px;

    #timer {
      height: 100%;
      background-color: var(--primary);
    }

    &:active {
      background-color: var(--guess-blank);
    }
  } //type

  .skip {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: var(--guess-input);
    border-radius: 6px;
    transition: ease-in 200ms;
    margin-top: 16px;
    padding: 10px 20px;
    opacity: 0;

    p {
      font-family: $font-handwriting;
      color: var(--text);
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    &:active {
      opacity: 0.7;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .skip.visible {
    opacity: 1;
    transition: all 100ms ease-in;
  }

  #missed-answer {
    width: 85%;
    padding: 4px 16px;
    color: #42885e;
    border-radius: 6px;
    text-align: center;
    margin-top: 20px;
  }

  .playbutton {
    cursor: pointer;
    border: none;
    background-color: var(--guess-input);
    padding: 10px 24px;
    border-radius: 40px;
    -webkit-tap-highlight-color: transparent;

    p {
      font-size: 26px;
      color: var(--text);
      margin: 0;
      font-family: $font-handwriting;
    }
  }

  .playbutton.transparent {
    background-color: transparent;
  }
}
